<template>
  <div class="contents register wedding scheduleBox">
    <div class="title flexB">
      <h2>스케줄 관리</h2>
    </div>
    <div class="box one">
      <div class="inputBox">
        <div class="flexL half">
          <p class="bold">예약상태</p>
          <el-select v-model="status">
            <el-option label="예약대기" value="request"></el-option>
            <el-option label="예약확정" value="confirm"></el-option>
            <el-option label="예약취소" value="cancel"></el-option>
          </el-select>
        </div>
        <div class="flexL half">
          <p class="bold">
            고객명
            <span>*</span>
          </p>
          <p v-if="editMode">
            <router-link :to="`/admin/users/detail?id=${user._id}`"
              >{{ user.username }}
              <span class="material-symbols-rounded">
                demography
              </span></router-link
            >
          </p>
          <div v-else class="plannerInput">
            <p @click="handleModal('user')">{{ user.username }}</p>
          </div>
        </div>

        <div class="flexL">
          <p class="bold">
            업체/전문가
            <span>*</span>
          </p>
          <p v-if="editMode">
            <router-link
              :to="`/admin/provider/register?id=${provider._id}`"
              v-if="provider"
              >{{ provider.providerName }}
              <span class="material-symbols-rounded">
                demography
              </span></router-link
            >
          </p>
          <div v-else class="plannerInput">
            <p @click="handleModal('provider')">
              {{ provider.providerName }}
            </p>
          </div>
        </div>

        <div class="flexL">
          <p class="bold">
            스케줄 날짜
            <span>*</span>
          </p>
          <el-date-picker v-model="date" type="date" placeholder="날짜 선택">
          </el-date-picker>
        </div>
        <div class="flexL">
          <p class="bold"></p>
          <el-time-select
            v-model="time"
            :picker-options="{
              start: '10:30',
              step: '00:30',
              end: '18:00',
            }"
            placeholder="시간 선택"
          >
          </el-time-select>
        </div>

        <div v-if="editMode">
          <div class="flexL half" v-if="name">
            <p class="bold">예약자명</p>
            <p>{{ name }}</p>
          </div>
          <div class="flexL half" v-if="mobileNo">
            <p class="bold">연락처</p>
            <p>{{ mobileNo }}</p>
          </div>
          <div class="flexL half" v-if="email">
            <p class="bold">이메일</p>
            <p>{{ email }}</p>
          </div>
        </div>

        <!-- <div class="flexL">
          <p class="bold">견적서</p>
          <router-link>{{ estimate }}</router-link>
        </div> -->
        <!-- <div class="flexL">
          <p class="bold">
            스케줄명
            <span>*</span>
          </p>
          <input type="text" v-model="title" />
        </div> -->
      </div>

      <div class="buttonWrap">
        <router-link to="/admin/schedule/manage">목록</router-link>
        <button class="basic" @click="submit" :disabled="inactive == true">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line delete" @click="handleDelete">
          삭제
        </button>
      </div>
    </div>

    <div class="modal" v-if="modalVisible">
      <div class="modal_bg" @click="modalReset"></div>
      <div class="modal_body">
        <div class="modal_header">
          <p>검색</p>
          <button @click="modalReset">
            <span class="material-icons-round"> clear </span>
          </button>
        </div>
        <div class="modal_content">
          <div class="searchWrap">
            <input
              type="text"
              placeholder="이름을 입력해 주세요"
              v-model="searchWord"
              @keyup.enter="search"
            />
            <button @click="search">
              <span class="material-icons-round"> search </span>
            </button>
          </div>
          <div class="tableBox">
            <table v-if="searchResults.length > 0">
              <tr>
                <th>이름</th>
              </tr>
              <tr
                v-for="(data, i) in searchResults"
                :key="i"
                @click="userSelect(data)"
              >
                <td v-if="userType == 'user'">{{ data.username }}</td>
                <td v-else>
                  {{ data.providerName }}
                </td>
              </tr>
            </table>
            <div
              class="nodata"
              v-else-if="searchResults.length == 0 && searchWord != ''"
            >
              <p>검색결과가 없습니다.</p>
            </div>
            <div class="pagination" v-if="searchResults.length > 0">
              <el-pagination
                layout="prev, pager, next"
                :total="modalTotal"
                :page-size="10"
                :page-count="6"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  createSchedule,
  fetchScheduleDetail,
  updateSchedule,
  removeSchedule,
  fetchUserList,
  fetchProviderList,
} from "@/api/admin";
import { format } from "@/mixins/format";

export default {
  mixins: [format],

  data() {
    return {
      editMode: false,
      moment: moment,
      scheduleId: "",
      title: "",
      category: "",
      content: "",
      date: "",
      time: "",
      scheduleType: "",
      currentPage: 1,
      total: 0,

      modalVisible: false,
      inactive: false,
      modalPage: 1,
      modalTotal: 0,
      searchResults: [],
      searchWord: "",
      user: "",
      provider: "",
      userType: "",
      providerId: "",
      estimateId: "",

      name: "",
      mobileNo: "",
      email: "",
      status: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "5");
  },

  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.scheduleId = this.$route.query.id;
      this.getScheduleDetail();
    }
  },
  methods: {
    getScheduleDetail() {
      fetchScheduleDetail(this.scheduleId).then((res) => {
        if (res.data.status == 200) {
          this.date = res.data.data.date;
          this.time = res.data.data.time;
          if (res.data.data.user) {
            this.user = res.data.data.user;
          }
          this.mobileNo = res.data.data.mobileNo;
          this.email = res.data.data.email;
          this.name = res.data.data.name;
          this.provider = res.data.data.provider;
          this.status = res.data.data.status;
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    getUserList() {
      let params = {
        page: this.modalPage,
        inputMode: "username",
        searchWord: this.searchWord,
        userType: this.userType,
      };

      fetchUserList(params).then((res) => {
        if (res.data.status == 200) {
          this.searchResults = res.data.users;
          this.modalTotal = res.data.total;
          this.modalPage = res.data.page;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    getProviderList() {
      let params = {
        page: this.modalPage,
        // inputMode: "username",
        // searchWord: this.searchWord,
      };
      fetchProviderList(params).then((res) => {
        if (res.data.status == 200) {
          this.searchResults = res.data.data;
          this.modalTotal = res.data.total;
          this.modalPage = res.data.page;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    handleCurrentChange(val) {
      if (this.modalVisible == true) {
        this.modalPage = val;
        this.getUserList();
      } else {
        this.currentPage = val;
      }
    },
    userSelect(data) {
      if (this.userType == "user") {
        this.user = data;
      } else {
        this.provider = data;
      }
      this.modalReset();
    },
    search() {
      this.modalPage = 1;
      this.searchResults = [];
      this.modalTotal = 0;
      this.getUserList();
    },
    modalReset() {
      this.searchWord = "";
      this.userType = "";
      this.modalPage = 1;
      this.searchResults = [];
      this.modalTotal = 0;
      this.modalVisible = !this.modalVisible;
    },
    handleModal(type) {
      this.modalVisible = !this.modalVisible;
      this.userType = type;
      if (type == "user") {
        this.getUserList();
      } else {
        this.getProviderList();
      }
    },

    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        removeSchedule(this.scheduleId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "스케쥴 정보가 삭제 되었습니다.",
              type: "success",
            });
            this.$router.replace("/admin/schedule/manage");
          }
        });
      });
    },

    submit() {
      if (this.user == "") {
        this.$alert("고객을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.provider == "") {
        this.$alert("업체/전문가를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.date == "" || this.time == "") {
        this.$alert("예약 날짜와 시간을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      this.inactive = true;

      let data = {
        scheduleId: this.scheduleId,
        provider: this.provider._id,
        user: this.user._id,
        date: moment(this.date).format("YYYY.MM.DD"),
        time: this.time,
        status: this.status,
      };

      if (this.editMode) {
        updateSchedule(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("스케쥴 정보가 반영 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/schedule/manage");
              this.$store.dispatch("getNewRequestList");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createSchedule(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("스케쥴 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/schedule/manage");
              this.$store.dispatch("getNewRequestList");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            this.inactive = false;
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.el-select {
  width: 200px !important;
}
</style>
